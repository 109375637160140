import React, { useState, useEffect } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import useMarketplaceConfigByLocation from '../../util/hooks/useMarketplaceConfigByLocation';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/Hubista-Facebook-share.png';
import twitterImage from '../../assets/Hubista-Twitter-share.png';
import css from './LandingPage.module.css';
import { subscribeEmailToGoogleSheet, resetSucceedState } from './LandingPage.duck';
import SectionTeaserMaybe from './components/SectionTeaserMaybe/SectionTeaserMaybe';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
    subscribeInProgress,
    subscribeError,
    subscribeSucceed,
    onSubscribeEmailToGoogleSheet,
    onResetSucceedState,
  } = props;

  const [teaserOpen, setTeaserOpen] = useState(true);

  // useEffect(() => {
  //   const isSubmitted = localStorage.getItem('submittedNewsletter');
  //   if (!isSubmitted) {
  //     setTeaserOpen(true);
  //   }
  // }, []);

  const onSubmitNewsletter = e => {
    return onSubscribeEmailToGoogleSheet(e).then(() => {
      localStorage.setItem('submittedNewsletter', true);
    });
  };

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${useMarketplaceConfigByLocation.canonicalRootURL}${facebookImage}`;

  let canTeaserShow;
  if (typeof window !== 'undefined') {
    const inFinlandSite =
      window.location.origin === process.env.REACT_APP_HUBISTA_DOMAIN_FINLAND_URL;
    const inSwedenSite = window.location.origin === process.env.REACT_APP_HUBISTA_DOMAIN_SWEDEN_URL;

    const isDev = process.env.REACT_APP_ENV !== 'production';
    canTeaserShow = !isDev && teaserOpen && (inFinlandSite || inSwedenSite);
  }

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${useMarketplaceConfigByLocation.canonicalRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <Modal
            hideClose={true}
            isOpen={false}
            onClose={() => {}}
            onManageDisableScrolling={() => false}
            id="LandingPage.NewsLetterPopUp"
            scrollLayerClassName={css.teaserModalLayer}
            containerClassName={css.teaserModalContainer}
          >
            <SectionTeaserMaybe
              intl={intl}
              subscribeInProgress={subscribeInProgress}
              subscribeError={subscribeError}
              showSubsribeForm={true}
              onSubscribeEmailToGoogleSheet={onSubmitNewsletter}
              subscribeSucceed={subscribeSucceed}
              onResetSucceedState={onResetSucceedState}
            />
          </Modal>
          <div className={css.heroContainer}>
            <SectionHero intl={intl} className={css.hero} history={history} location={location} />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionLocations intl={intl} />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHowItWorks
                  currentUserListing={currentUserListing}
                  currentUserListingFetched={currentUserListingFetched}
                />
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer
            subscribeInProgress={subscribeInProgress}
            subscribeError={subscribeError}
            showSubsribeForm={true}
            onSubscribeEmailToGoogleSheet={onSubscribeEmailToGoogleSheet}
            subscribeSucceed={subscribeSucceed}
            onResetSucceedState={onResetSucceedState}
          />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched } = state.user;
  const { subscribeInProgress, subscribeError, subscribeSucceed } = state.LandingPage;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    subscribeInProgress,
    subscribeError,
    subscribeSucceed,
  };
};
const mapDispatchToProps = dispatch => ({
  onSubscribeEmailToGoogleSheet: email => dispatch(subscribeEmailToGoogleSheet(email)),
  onResetSucceedState: _ => dispatch(resetSucceedState()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LandingPageComponent);

export default LandingPage;

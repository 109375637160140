import React from 'react';
import { SectionNewsletter } from '../../../../components';
import css from './SectionTeaserMaybe.module.css';
const SectionTeaserMaybe = props => {
  const { intl, setTeaserOpen, ...rest } = props;

  const teaserTitle = intl.formatMessage({
    id: 'SectionTeaserMaybe.teaserTitle',
  });

  const teaserDescription = intl.formatMessage({
    id: 'SectionTeaserMaybe.teaserDescription',
  });

  const teaserDescription2 = intl.formatMessage({
    id: 'SectionTeaserMaybe.teaserDescription2',
  });

  return (
    <div onClick={e => e.stopPropagation()} className={css.root}>
      <h2 className={css.teaserTitle}>{teaserTitle}</h2>
      <p className={css.teaserDescription}>{teaserDescription}</p>
      <p className={css.teaserDescription}>{teaserDescription2}</p>
      <SectionNewsletter rootClassName={css.newsLetterForm} {...rest} />
    </div>
  );
};

export default SectionTeaserMaybe;
